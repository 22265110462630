import { BlogIndexPageQuery, CdnImageDataFragment } from 'gatsby/graphqlTypes';
import {
  BodyText,
  Heading,
  InternalLink,
  TextSize
} from 'components/atoms/typography';
import { Link, graphql } from 'gatsby';

import { AppPageProps } from 'gatsby/types';
import { Container } from 'components/atoms/layout/Container';
import FullWidthImage from '@svelte/reactify/FullWidthImage';
import PageContentLayout from 'components/layouts/PageContentLayout';
import React from 'react';
import ResponsiveImage from '@svelte/reactify/ResponsiveImage';
import SectionWithTitle from 'components/molecules/layout/SectionWithTitle';
import { makeBlogPostPath } from 'lib/navigation/dynamic';

export { Head } from 'components/atoms/Head';

export type Props = AppPageProps<BlogIndexPageQuery>;

export const query = graphql`
  query BlogIndexPage {
    c: strapiBlog {
      mainHeading
      subheading
      bannerMedia {
        ...CdnImageData
      }
    }
    latest: allStrapiBlogPost(
      limit: 5
      sort: { order: DESC, fields: publishedAt }
    ) {
      nodes {
        title
        summary
        pageMetadata {
          slug
        }
        date: publishedAt
        rt: contentReadingTime
        img: bannerMedia {
          ...CdnImageData
        }
      }
    }
  }
`;

const BlogIndex: React.FC<Props> = ({ data, pageContext }) => {
  const { mainHeading, subheading, bannerMedia } = data.c as NonNullable<
    BlogIndexPageQuery['c']
  >;
  const latest = data.latest.nodes as NonNullable<
    BlogIndexPageQuery['latest']['nodes']
  >;

  return (
    <PageContentLayout footerNavigation={pageContext.footerNavigation}>
      <div className="relative flex min-h-rhythm11 flex-col justify-end">
        {!!bannerMedia && (
          <div className="absolute inset-0 h-full w-full">
            <FullWidthImage eager data={bannerMedia} colour={['f5b368', 40]} />
          </div>
        )}

        <Container className="relative z-20 w-full bg-pearl-500 bg-opacity-60 py-rhythm1 lg:backdrop-blur">
          <Heading bold level={1}>
            {mainHeading}
          </Heading>
          <Heading level={3}>{subheading}</Heading>
        </Container>
      </div>
      <Container className="grid grid-cols-3 gap-rhythm4 py-rhythm3">
        <div className="col-span-3 lg:col-span-2">
          <SectionWithTitle level={2} title="Latest">
            <div className="space-y-rhythm1">
              {latest.map(post => {
                const href = makeBlogPostPath(
                  pageContext.appNav,
                  post.pageMetadata.slug
                );
                const publishedDate =
                  post.date &&
                  new Intl.DateTimeFormat('en-GB', {
                    dateStyle: 'long'
                  }).format(new Date(post.date));

                return (
                  <div
                    key={post.title}
                    className="group relative cursor-pointer border-4 border-t-0 border-pearl-500 bg-pearl-200 hover:border-primary-400"
                  >
                    <ResponsiveImage
                      data={post.img as CdnImageDataFragment}
                      height="h-rhythm9 xl:h-rhythm10"
                      className="border-t-4 border-primary-400"
                    />
                    <div className="p-rhythm1">
                      <Heading
                        level={3}
                        withoutSpacing
                        className="mb-rhythm-1 visited:text-primary-400 group-hover:no-underline"
                        bold
                      >
                        <Link to={href}>
                          <span
                            aria-hidden="true"
                            className="absolute inset-0"
                          />
                          {post.title}
                        </Link>
                      </Heading>
                      <BodyText size={TextSize.LongPrimer}>
                        {publishedDate}
                        <span className="px-2">•</span>
                        {post.rt}
                      </BodyText>
                      <BodyText>{post.summary}</BodyText>
                      <InternalLink className="z-10" to={''}>
                        Continue reading
                      </InternalLink>
                    </div>
                  </div>
                );
              })}
            </div>
          </SectionWithTitle>
        </div>
        <div className="col-span-3 lg:col-span-1">
          <SectionWithTitle level={2} size={'md'} title="Popular">
            {latest.map(post => (
              <div key={post.title}>
                <Heading
                  level={4}
                  size={TextSize.BodyCopy}
                  withoutSpacing
                  className="mb-rhythm-1 visited:text-primary-400 hover:underline"
                >
                  <Link
                    to={makeBlogPostPath(
                      pageContext.appNav,
                      post.pageMetadata.slug
                    )}
                    className=""
                  >
                    {post.title}
                  </Link>
                </Heading>
              </div>
            ))}
          </SectionWithTitle>
        </div>
      </Container>
    </PageContentLayout>
  );
};

export default BlogIndex;
