import {
  Heading,
  HeadingLevel,
  TextCase,
  TextSize
} from 'components/atoms/typography';

import React from 'react';
import { makeClassNames } from 'lib/util';

type SectionWithTitleProps = PropsWithChildren &
  Readonly<{
    level: HeadingLevel;
    size?: 'md' | 'lg';
    title: string;
  }>;

const SectionWithTitle: React.FC<SectionWithTitleProps> = ({
  level,
  size = 'lg',
  title,
  children
}) => {
  return (
    <div
      className={makeClassNames(
        size === 'lg' ? 'border-t-8' : 'border-t-4',
        'border-primary pt-rhythm1'
      )}
    >
      <Heading
        size={size === 'lg' ? TextSize.Pica : TextSize.BodyCopy}
        level={level}
        textCase={TextCase.Uppercase}
        withoutSpacing
        className="mb-rhythm2"
        bold
      >
        {title}
      </Heading>
      {children}
    </div>
  );
};

export default SectionWithTitle;
